import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('ReservationPrintService')

@Injectable({ providedIn: 'root' })
export class ReservationExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  /**
   * Cái tên nói lên tất cả rồi, đọc làm éo gì :/
   */
  onGenerateExcelReservationList(
    title,
    data: any[],
    sheetname,
    filename,
    logo
  ) {
    const headerRow2Columns = [
      '',
      'Timestamp',
      'Booking Ref.',
      'Guest Name',
      'Check-In',
      'Check-Out',
      'Channel ',
      'Agent Name',
      'Dossier #',
      'E-Mail',
      'Phone Number',
      'Address',
      'Country of Residence',
      'Nationality',
      '# of Pax',
      'Room Type (s)',
      'Package Type',
      '# of Rooms',
      'Length of Stay (night)',
      'Room(s) No.',
      'Status',
      'Notes',
      'FOC',
      'Rooms Price',
      'Discount Room',
      'Excursion Price',
      'Discount Excursion',
      'Transfer Price',
      'Discount Transfer',
      'F&B Price',
      'Discount F&B',
      'Tax',
      'Total',
      'Payment(s)',
      'Total Outstanding',
      'Paid in Full?',
    ]
    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: { tabColor: { argb: 'FFFFFF00' } },
    })
    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 2 },
      { key: 'C1', width: 11.67 },
      { key: 'C2', width: 24 },
      { key: 'C3', width: 24 },
      { key: 'C4', width: 12 },
      { key: 'C5', width: 12 },
      { key: 'C6', width: 12 },
      { key: 'C7', width: 20 },
      { key: 'C8', width: 14 },
      { key: 'C9', width: 20 },
      { key: 'C10', width: 16 },
      { key: 'C11', width: 24 },
      { key: 'C12', width: 20 },
      { key: 'C13', width: 12 },
      { key: 'C14', width: 6 },
      { key: 'C15', width: 14 },
      { key: 'C16', width: 14 },
      { key: 'C17', width: 11 },
      { key: 'C18', width: 20 },
      { key: 'C19', width: 11 },
      { key: 'C20', width: 12 },
      { key: 'C21', width: 24 },
      { key: 'C22', width: 5 },
      { key: 'C23', width: 14 },
      { key: 'C24', width: 14 },
      { key: 'C25', width: 14 },
      { key: 'C26', width: 14 },
      { key: 'C27', width: 14 },
      { key: 'C28', width: 14 },
      { key: 'C29', width: 14 },
      { key: 'C30', width: 14 },
      { key: 'C31', width: 14 },
      { key: 'C32', width: 14 },
      { key: 'C33', width: 14 },
      { key: 'C34', width: 14 },
      { key: 'C35', width: 14 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    let titleRow = worksheet.addRow([' ', titleText])
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleRow.border = {
      bottom: { style: 'medium' },
    }
    worksheet.getCell('A3').border = {}

    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'G2:G3')
    }

    worksheet.addRow([])

    let headerRow1Column = []
    for (let index = 0; index < 36; index++) {
      if (index == 1) {
        headerRow1Column[index] = 'Booking Info.'
      } else if (index === 22) {
        headerRow1Column[index] = 'Payment Info.'
      } else {
        headerRow1Column[index] = ''
      }
    }

    let headerRow1 = worksheet.addRow(headerRow1Column)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    worksheet.mergeCells('B5:V5')
    worksheet.mergeCells('W5:AJ5')
    worksheet.getCell('B5').alignment = {
      horizontal: 'center',
    }
    worksheet.getCell('B5').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD9E1F2' },
    }
    worksheet.getCell('W5').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE2EFDA' },
    }
    worksheet.getCell('W5').alignment = {
      horizontal: 'center',
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center' }
      }
    })

    let headerRow2 = worksheet.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center' }
      }
    })

    data.map(r => {
      let status = r.status
      let dataRowColumn = this.generateReservationInfoColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)

      if (status == 'Cancelled') {
        dataRow.font = {
          name: 'Calibri',
          size: 10,
          bold: false,
          strike: true,
        }
      } else {
        let channel = r.channel
        let rowBgColor = 'FFFFFFFF'
        switch (channel) {
          case 'Direct':
            rowBgColor = 'FFFFF2CC'
            break
          case 'Agent':
            rowBgColor = 'FFD9E1F2'
            break
          case 'OTA':
            rowBgColor = 'FFE2EFDA'
            break
          case 'Other':
            rowBgColor = 'FFD0CECE'
            break

          default:
            break
        }
        dataRow.getCell(2).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: rowBgColor },
        }

        dataRow.getCell(7).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: rowBgColor },
        }
        dataRow.font = {
          name: 'Calibri',
          size: 10,
          bold: false,
          strike: false,
        }
      }
      dataRow.getCell(2).alignment = { horizontal: 'center' }
      dataRow.getCell(2).numFmt = 'DD-MMM-YY'
      dataRow.getCell(5).alignment = { horizontal: 'center' }
      dataRow.getCell(5).numFmt = 'DD-MMM-YY'
      dataRow.getCell(6).alignment = { horizontal: 'center' }
      dataRow.getCell(6).numFmt = 'DD-MMM-YY'
      dataRow.getCell(7).alignment = { horizontal: 'center' }
      dataRow.getCell(11).alignment = { horizontal: 'center' }
      dataRow.getCell(15).alignment = { horizontal: 'center' }
      dataRow.getCell(18).alignment = { horizontal: 'centeßr' }
      dataRow.getCell(19).alignment = { horizontal: 'center' }
      dataRow.getCell(21).alignment = { horizontal: 'center' }
      dataRow.getCell(23).alignment = { horizontal: 'center' }
      dataRow.getCell(24).numFmt = '#,##0.00'
      dataRow.getCell(25).numFmt = '#,##0.00'
      dataRow.getCell(26).numFmt = '#,##0.00'
      dataRow.getCell(27).numFmt = '#,##0.00'
      dataRow.getCell(28).numFmt = '#,##0.00'
      dataRow.getCell(29).numFmt = '#,##0.00'
      dataRow.getCell(30).numFmt = '#,##0.00'
      dataRow.getCell(31).numFmt = '#,##0.00'
      dataRow.getCell(32).numFmt = '#,##0.00'
      dataRow.getCell(33).numFmt = '#,##0.00'
      dataRow.getCell(34).numFmt = '#,##0.00'
      dataRow.getCell(35).numFmt = '#,##0.00'
      dataRow.getCell(36).alignment = { horizontal: 'center' }
    })

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  onGenerateExcelReservationActivities(
    title,
    data: any[],
    sheetname,
    filename,
    logo
  ) {
    const headerRowColumns = [
      '',
      'Timestamp',
      'Booking Ref.',
      'Room Number',
      'Guest Name',
      'Check In',
      'Check Out',
      'Type',
      'Activity',
      'Date',
      'Start Time',
      'Quantity',
      'Adults',
      'Children',
      'Driver Name',
      'Confirm',
      'Total Price',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: { tabColor: { argb: 'FFFFFF00' } },
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 2 },
      { key: 'C1', width: 11.67 },
      { key: 'C2', width: 20 },
      { key: 'C3', width: 20 },
      { key: 'C4', width: 24 },
      { key: 'C5', width: 10 },
      { key: 'C6', width: 10 },
      { key: 'C7', width: 15 },
      { key: 'C8', width: 26 },
      { key: 'C9', width: 10 },
      { key: 'C10', width: 10 },
      { key: 'C11', width: 10 },
      { key: 'C12', width: 10 },
      { key: 'C13', width: 10 },
      { key: 'C14', width: 20 },
      { key: 'C15', width: 10 },
      { key: 'C16', width: 15 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    let titleRow = worksheet.addRow([' ', titleText])
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleRow.border = {
      bottom: { style: 'medium' },
    }
    worksheet.getCell('A3').border = {}

    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    let headerRow = worksheet.addRow(headerRowColumns)
    headerRow.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center' }
      }
    })

    data.map(r => {
      let dataRowColumn = this.generateReservationActitiviesColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.getCell(2).alignment = { horizontal: 'center' }
      dataRow.getCell(2).numFmt = 'DD-MMM-YY'
      dataRow.getCell(3).alignment = { horizontal: 'left' }
      dataRow.getCell(4).alignment = { horizontal: 'left' }
      dataRow.getCell(5).alignment = { horizontal: 'left' }
      dataRow.getCell(6).alignment = { horizontal: 'center' }
      dataRow.getCell(7).alignment = { horizontal: 'center' }
      dataRow.getCell(8).alignment = { horizontal: 'left' }
      dataRow.getCell(8).numFmt = 'DD-MMM-YY'
      dataRow.getCell(9).alignment = { horizontal: 'left' }
      dataRow.getCell(10).alignment = { horizontal: 'center' }
      dataRow.getCell(11).alignment = { horizontal: 'center' }
      dataRow.getCell(12).alignment = { horizontal: 'right' }
      dataRow.getCell(14).alignment = { horizontal: 'center' }
      dataRow.getCell(15).alignment = { horizontal: 'right' }
      dataRow.getCell(16).alignment = { horizontal: 'center' }
      dataRow.getCell(17).alignment = { horizontal: 'right' }
      dataRow.getCell(17).numFmt = '#,##0.00'
    })

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateReservationInfoColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.timestamp)
    row.push(data.booking_ref)
    row.push(data.guest_name)
    row.push(data.check_in)
    row.push(data.check_out)
    row.push(data.channel)
    row.push(data.agent_name)
    row.push(data.dossier)
    row.push(data.guest_mail)
    row.push(data.guest_phone_numer)
    row.push(data.guest_address_line_1)
    row.push(data.guest_country)
    row.push(data.guest_nationality)
    row.push(data.number_of_pax)
    row.push(data.room_types)
    row.push(data.package_type)
    row.push(data.number_of_rooms)
    row.push(data.length_of_stay)
    row.push(data.rooms)
    row.push(data.status)
    row.push(data.notes)
    const isFoc = data.reservations_foc === 'yes' ? '✓' : ''
    row.push(isFoc)
    row.push(data.rooms_price)
    row.push(data.rooms_discount)
    row.push(data.excursion_price)
    row.push(data.excursion_discount)
    row.push(data.transfer_price)
    row.push(data.transfer_discount)
    row.push(data.fb_price)
    row.push(data.fb_discount)
    row.push(data.tax)
    row.push(data.total)
    row.push(data.payment)
    row.push(data.total_outstanding)
    row.push(data.full_paid)
    return row
  }

  private generateReservationActitiviesColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.timestamp)
    row.push(data.booking_ref)
    row.push(data.room_name)
    row.push(data.guest_name)
    row.push(data.check_in)
    row.push(data.check_out)
    row.push(data.type)
    row.push(data.activity)
    row.push(data.date)
    row.push(data.stime)
    row.push(data.quantity)
    row.push(data.adults)
    row.push(data.children)
    row.push(data.driver_name)
    row.push(data.confirmed)
    row.push(data.total_price)
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
