import { NgModule } from '@angular/core'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { MatButtonModule } from '@angular/material/button'
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatToolbarModule } from '@angular/material/toolbar'
import 'hammerjs'

import { FuseModule } from '@fuse/fuse.module'
import { HMPModule } from '@hmp'
import { fuseConfig } from './fuse-config'

import { AppComponent } from './app.component'

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader'

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { NgSelectFormFieldControlDirective } from './helper/directives/ng-select.directive'
import {
  CalendarModule as AngularCalendarModule,
  DateAdapter,
} from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFireMessagingModule } from '@angular/fire/messaging'
import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireStorageModule } from '@angular/fire/storage'
import { environment } from 'environments/environment'
import { WebNotificationsService } from './helper/services/web-notifications.service'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { WebSharedModule } from './others/web-shared/web-shared.module'
import { ExportModalModule } from './others/export-modal/export-modal.module'
import { QuickLaunchNavigationModule } from './others/quick-launch-navigation/quick-launch-navigation.module'
import { ToastrModule } from 'ngx-toastr'
import { AppToastComponent } from './apptoast.component'
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components'
import { FuseSharedModule } from '@fuse/shared.module'
import { BrowserModule } from '@angular/platform-browser'
import { GalleryModule } from '@ks89/angular-modal-gallery'
import { NgxMaskModule } from 'ngx-mask'
import { NgxPayPalModule } from 'ngx-paypal'
import { ChartsModule, ThemeService } from 'ng2-charts'
// import { LayoutModule } from './layout/layout.module';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// /**
//  * AoT requires an exported function for factories
//  */
// tslint:disable-next-line: typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {
      prefix: './assets/i18n/',
      suffix: '.json',
    },
    // { prefix: "./assets/i18n/auth/", suffix: ".json" },
    // { prefix: "./assets/i18n/core/", suffix: ".json" },
    // { prefix: "./assets/i18n/front/", suffix: ".json" },
    // { prefix: "./assets/i18n/shared/", suffix: ".json" }
  ])
}

const appRoutes: Routes = [
  {
    path: 'server-maintenance',
    loadChildren: () =>
      import('./others/server-maintenance/server-maintenance.module').then(
        m => m.ServerMaintenanceModule
      ),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./others/start-page/start-page.module').then(
        m => m.StartPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./users/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./users/request-demo/request-demo.module').then(
        m => m.RequestDemoModule
      ),
    // loadChildren: () =>
    //   import('./users/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'requestdemo',
    loadChildren: () =>
      import('./users/request-demo/request-demo.module').then(
        m => m.RequestDemoModule
      ),
  },
  {
    path: 'check-in-information',
    loadChildren: () =>
      import('./users/guest-check-in-form/guest-check-in-form.module').then(
        m => m.GuestCheckInFormModule
      ),
  },
  {
    path: 'denied',
    loadChildren: () =>
      import(
        './others/not-assigned-permission/not-assigned-permission.module'
      ).then(m => m.NotAssignedPermissionModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./users/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./users/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./main/calendar/calendar.module').then(m => m.CalendarModule),
  },
  {
    path: 'activity',
    loadChildren: () =>
      import('./main/activity/activity.module').then(m => m.ActivityModule),
  },
  {
    path: 'rooms',
    loadChildren: () =>
      import('./main/rooms/rooms.module').then(m => m.RoomsModule),
  },
  {
    path: 'direct-booking/customize',
    loadChildren: () =>
      import(
        './main/direct-booking-customize/direct-booking-customize.module'
      ).then(m => m.DirectBokingCustomizeModule),
  },
  {
    path: 'direct-booking-setting/common-pages',
    loadChildren: () =>
      import(
        './main/direct-booking-setting/common-pages/common-pages.module'
      ).then(m => m.CommonPagesModule),
  },
  {
    path: 'direct-booking-setting/direct-booking-packages',
    loadChildren: () =>
      import(
        './main/direct-booking-setting/direct-booking-packages/direct-booking-packages.module'
      ).then(m => m.DirectBookingPackagesModule),
  },
  {
    path: 'direct-booking/settings',
    loadChildren: () =>
      import('./main/direct-booking-settings/direct-booking-settings.module').then(
        m => m.DirectBookingSettingsCustomizeModule
      ),
  },
  {
    path: 'notice',
    loadChildren: () =>
      import('./main/notice/notice.module').then(m => m.NoticeModule),
  },
  {
    path: 'bulletin',
    loadChildren: () =>
      import('./main/bulletin/bulletin.module').then(m => m.BulletinModule),
  },
  {
    path: 'housekeeping',
    loadChildren: () =>
      import('./main/housekeeping/housekeeping.module').then(
        m => m.HousekeepingModule
      ),
  },
  {
    path: 'packages',
    loadChildren: () =>
      import('./main/packages/packages.module').then(m => m.PackagesModule),
  },
  {
    path: 'agent-class',
    loadChildren: () =>
      import('./main/agent/agent.module').then(m => m.AgentModule),
  },
  {
    path: 'hr',
    loadChildren: () => import('./main/hr/hr.module').then(m => m.HrModule),
  },
  {
    path: 'client-manager',
    loadChildren: () =>
      import('./main/hr/client-manager.module').then(
        m => m.ClientManagerModule
      ),
  },
  {
    path: 'agent-manager',
    loadChildren: () =>
      import('./main/hr/agent-manager.module').then(m => m.AgentManagerModule),
  },
  {
    path: 'chats',
    loadChildren: () =>
      import('./main/chat-room/chat-room.module').then(m => m.ChatRoomModule),
  },
  {
    path: 'promotions',
    loadChildren: () =>
      import('./main/discount-promotions/discount-promotions.module').then(
        m => m.DiscountPromotionsModule
      ),
  },
  {
    path: 'supplier',
    loadChildren: () =>
      import('./main/supplier/supplier.module').then(m => m.SupplierModule),
  },
  {
    path: 'food-beverage',
    loadChildren: () =>
      import('./main/food-beverage/food-beverage.module').then(
        m => m.FoodBeverageModule
      ),
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import('./main/schedule/schedule.module').then(m => m.ScheduleModule),
  },
  {
    path: 'payroll',
    loadChildren: () =>
      import('./main/payroll/payroll.module').then(m => m.PayrollModule),
  },
  {
    path: 'roomtypes',
    loadChildren: () =>
      import('./main/room-types/room-types.module').then(
        m => m.RoomTypesModule
      ),
  },
  {
    path: 'seasons',
    loadChildren: () =>
      import('./main/season/season.module').then(m => m.SeasonModule),
  },
  {
    path: 'statutory-report',
    loadChildren: () =>
      import(
        './main/statutory-report-generator/statutory-report-generator.module'
      ).then(m => m.StatutoryReportGeneratorModule),
  },
  {
    path: 'ar-register',
    loadChildren: () =>
      import('./main/ar-register/ar-register.module').then(
        m => m.ARRegisterModule
      ),
  },
  {
    path: 'dynamic-invoice-generator',
    loadChildren: () =>
      import(
        './main/dynamic-invoice-generator/dynamic-invoice-generator.module'
      ).then(m => m.DynamicInvoiceGeneratorModule),
  },
  {
    path: 'transaction-register',
    loadChildren: () =>
      import('./main/transaction-register/transaction-register.module').then(
        m => m.TransactionRegisterModule
      ),
  },
  {
    path: 'assettype',
    loadChildren: () =>
      import('./main/asset-type/asset-type.module').then(
        m => m.AssetTypeModule
      ),
  },
  {
    path: 'budget-builder',
    loadChildren: () =>
      import('./main/budget-builder/budget-builder.module').then(
        m => m.BudgetBuilderModule
      ),
  },
  {
    path: 'asset',
    loadChildren: () =>
      import('./main/asset/asset.module').then(m => m.AssetModule),
  },
  {
    path: 'vendor',
    loadChildren: () =>
      import('./main/vendor/vendor.module').then(m => m.VendorModule),
  },
  {
    path: 'revenue',
    loadChildren: () =>
      import('./main/revenue/revenue.module').then(m => m.RevenueModule),
  },
  {
    path: 'expense',
    loadChildren: () =>
      import('./main/expense/expense.module').then(m => m.ExpenseModule),
  },
  {
    path: 'activity-type',
    loadChildren: () =>
      import('./main/activity-type/activity-type.module').then(
        m => m.ActivityTypeModule
      ),
  },
  {
    path: 'purchase',
    loadChildren: () =>
      import('./main/purchase/purchase.module').then(m => m.PurchaseModule),
  },
  {
    path: 'paybill',
    loadChildren: () =>
      import('./main/paybill/paybill.module').then(m => m.PaybillModule),
  },
  {
    path: 'salescalls',
    loadChildren: () =>
      import('./main/sales-calls/sales-calls.module').then(
        m => m.SalesCallsModule
      ),
  },
  {
    path: 'cashjournal',
    loadChildren: () =>
      import('./main/cashjournal/cashjournal.module').then(
        m => m.CashjournalModule
      ),
  },
  {
    path: 'digital-assets',
    loadChildren: () =>
      import('./main/digital-assets/digital-assets.module').then(
        m => m.DigitalAssetsModule
      ),
  },
  {
    path: 'table-manager',
    loadChildren: () =>
      import('./main/table-manager/table-manager.module').then(
        m => m.TableManagerModule
      ),
  },
  {
    path: 'fb-daily-report',
    loadChildren: () =>
      import('./main/fb-daily-report/fb-daily-report.module').then(
        m => m.FBDailyReportModule
      ),
  },
  {
    path: 'category-food-daily-report',
    loadChildren: () =>
      import(
        './main/category-food-daily-report/category-food-daily-report.module'
      ).then(m => m.CategoryFoodDailyReportModule),
  },
  {
    path: 'category-beverage-daily-report',
    loadChildren: () =>
      import(
        './main/category-beverage-daily-report/category-beverage-daily-report.module'
      ).then(m => m.CategoryBeverageDailyReportModule),
  },
  {
    path: 'fd-daily-report',
    loadChildren: () =>
      import('./main/fd-daily-report/fd-daily-report.module').then(
        m => m.FDDailyReportModule
      ),
  },
  {
    path: 'task-manager',
    loadChildren: () =>
      import('./main/task-manager/task-manager.module').then(
        m => m.TaskManagerModule
      ),
  },
  {
    path: 'planner',
    loadChildren: () =>
      import('./main/planner/planner.module').then(m => m.PlannerModule),
  },
  {
    path: 'event-planner',
    loadChildren: () =>
      import('./main/event-planner/event-planner.module').then(
        m => m.EventPlannerModule
      ),
  },
  {
    path: 'report-generator',
    loadChildren: () =>
      import('./main/report-generator/report-generator.module').then(
        m => m.ReportGeneratorModule
      ),
  },
  {
    path: 'pos',
    loadChildren: () => import('./main/pos/pos.module').then(m => m.PosModule),
  },
  {
    path: 'taxes',
    loadChildren: () =>
      import('./main/taxes/taxes.module').then(m => m.TaxesModule),
  },
  {
    path: 'service-charges',
    loadChildren: () =>
      import('./main/service-charges/service-charges.module').then(
        m => m.ServiceChargesModule
      ),
  },
  {
    path: 'rate-code',
    loadChildren: () =>
      import('./main/rate-code/rate-code.module').then(m => m.RateCodeModule),
  },
  {
    path: 'accounting-codes',
    loadChildren: () =>
      import('./main/accounting-codes/accounting-codes.module').then(
        m => m.AccountingCodesModule
      ),
  },
  {
    path: 'payment-method',
    loadChildren: () =>
      import('./main/payment-method/payment-method.module').then(
        m => m.PaymentMethodModule
      ),
  },
  {
    path: 'maintenances',
    loadChildren: () =>
      import('./main/maintenances/maintenances.module').then(
        m => m.MaintenancesModule
      ),
  },
  {
    path: 'reservations',
    loadChildren: () =>
      import('./main/reservations/reservations.module').then(
        m => m.ReservationsModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./users/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./main/editbusiness/editbusiness.module').then(
        m => m.EditbusinessModule
      ),
  },
  {
    path: 'hotel',
    loadChildren: () =>
      import('./main/hotel-info/hotel-info.module').then(
        m => m.HotelInfoModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./main/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'permissions',
    loadChildren: () =>
      import('./main/permissions/permissions.module').then(
        m => m.PermissionsModule
      ),
  },
  {
    path: 'inventory-master',
    loadChildren: () =>
      import('./main/inventory-master/inventory-master.module').then(
        m => m.InventoryMasterModule
      ),
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./main/inventory/inventory.module').then(m => m.InventoryModule),
  },
  {
    path: 'packages',
    loadChildren: () =>
      import('./main/packages/packages.module').then(m => m.PackagesModule),
  },
  {
    path: 'production-reports',
    loadChildren: () =>
      import('./main/production-reports/production-reports.module').then(
        m => m.ProductionReportsModule
      ),
  },
  {
    path: 'report-package-sales',
    loadChildren: () =>
      import('./main/package-sales-report/package-sales-report.module').then(
        m => m.PackageSalesReportModule
      ),
  },
  {
    path: 'menu_outlets',
    loadChildren: () =>
      import(
        './main/pos-front-desk/pos-front-desk-menu/pos-front-desk-menu.module'
      ).then(m => m.PosFrontDeskMenuModule),
  },
  // {
  //   path: 'order_outlets',
  //   loadChildren: () =>
  //     import(
  //       './main/pos-front-desk/pos-front-desk-order/pos-front-desk-order.module'
  //     ).then(m => m.PosFrontDeskOrderModule),
  // },
  {
    path: 'order_outlets',
    loadChildren: () =>
      import('./main/pos-front-desk/pos-front-desk/pos-front-desk.module').then(
        m => m.PosFrontDeskModule
      ),
  },
  {
    path: 'optimizer',
    loadChildren: () =>
      import('./main/channel-manager/channel-manager.module').then(
        m => m.ChannelManagerModule
      ),
  },
  {
    path: 'optimizer/activity-log',
    loadChildren: () =>
      import('./main/ota-activities-log/ota-activities-log.module').then(
        m => m.OTAActivitiesLogModule
      ),
  },
  {
    path: 'expense-journal',
    loadChildren: () =>
      import('./main/expense-journal/expense-journal.module').then(
        m => m.ExpenseJournalModule
      ),
  },
  {
    path: 'service-charge-register',
    loadChildren: () =>
      import('./main/service-charge-register/service-charge-register.module').then(
        m => m.ServiceChargeRegisterModule
      ),
  },
  {
    path: 'report-statement-cash',
    loadChildren: () =>
      import('./main/expense-journal/report-statement-cash.module').then(
        m => m.ReportStatementCastModule
      ),
  },
  {
    path: 'asset-owner',
    loadChildren: () =>
      import('./main/asset-owner/asset-owner.module').then(
        m => m.AssetOwnerModule
      ),
  },
  {
    path: 'financing-journal',
    loadChildren: () =>
      import('./main/financing-journal/financing-journal.module').then(
        m => m.FinancingJournalModule
      ),
  },
  {
    path: 'extra-amenities',
    loadChildren: () =>
      import('./main/extra-amenities/extra-amenities.module').then(
        m => m.ExtraAmenitiesModule
      ),
  },
  {
    path: 'forecast-wizard',
    loadChildren: () =>
      import('./main/forecast-wizard/forecast-wizard.module').then(
        m => m.ForecastWizardModule
      ),
  },
  {
    path: 'guests',
    loadChildren: () =>
      import('./main/guests/guests.module').then(m => m.GuestsModule),
  },
  {
    path: 'resident-folio',
    loadChildren: () =>
      import('./main/resident-folio/resident-folio.module').then(
        m => m.ResidentFolioModule
      ),
  },
  {
    path: 'unit-owner-folio',
    loadChildren: () =>
      import('./main/unit-owner-folio/unit-owner-folio.module').then(
        m => m.UnitOwnerFolioModule
      ),
  },
  {
    path: 'payment-register',
    loadChildren: () =>
      import('./main/payment-register/payment-register.module').then(
        m => m.PaymentRegisterModule
      ),
  },
  {
    path: 'revenue-report',
    loadChildren: () =>
      import('./main/daily-revenue-report/item.module').then(
        m => m.DailyRevenueReportModule
      ),
  },
  {
    path: 'payment-gateway-records',
    loadChildren: () =>
      import('./main/daily-payment-gateway-report/item.module').then(
        m => m.DailyPaymentGateWayReportModule
      ),
  },
  {
    path: 'reservation-report',
    loadChildren: () =>
      import('./main/reservation-report/item.module').then(
        m => m.ReservationReportModule
      ),
  },
  {
    path: 'demo-account',
    loadChildren: () =>
      import('./main/demo-account/demo-account.module').then(
        m => m.DemoAccountModule
      ),
  },
  {
    path: 'guest-contact-center',
    loadChildren: () =>
      import('./main/guest-contact-center/guest-contact-center.module').then(
        m => m.GuestContactCenterModule
      ),
  },
  {
    path: 'guest-letter',
    loadChildren: () =>
      import('./main/guest-letter/guest-letter.module').then(
        m => m.GuestLetterModule
      ),
  },
  {
    path: 'email-settings',
    loadChildren: () =>
      import('./main/email-setting/email-setting.module').then(
        m => m.EmailSettingModule
      ),
  },
  {
    path: 'printer-settings',
    loadChildren: () =>
      import('./main/pos-printer-settings/pos-printer-settings.module').then(
        m => m.POSPrinterSettingsModule
      ),
  },
  {
    path: 'restaurant-manager',
    loadChildren: () =>
      import('./main/restaurants/restaurants.module').then(
        m => m.RestaurantsModule
      ),
  },
  {
    path: 'frontdesk-manager',
    loadChildren: () =>
      import('./main/front-desk/front-desk.module').then(
        m => m.FrontDeskModule
      ),
  },
  {
    path: 'front-desk-excursions',
    loadChildren: () =>
      import('./main/front-desk-excursions/front-desk-excursions.module').then(
        m => m.FrontDeskExcursionsModule
      ),
  },
  {
    path: 'front-desk-transfers',
    loadChildren: () =>
      import('./main/front-desk-transfers/front-desk-transfers.module').then(
        m => m.FrontDeskTransfersModule
      ),
  },
  {
    path: 'website-settings',
    loadChildren: () =>
      import('./main/website-settings/website-settings.module').then(
        m => m.WebsiteSettingsModule
      ),
  },
  {
    path: 'website-customize',
    loadChildren: () =>
      import('./main/website-customize/website-customize.module').then(
        m => m.WebsiteCustomizeModule
      ),
  },
  {
    path: 'guestfolio',
    loadChildren: () =>
      import('./main/guest-folio/guest-folio.module').then(
        m => m.GuestFolioModule
      ),
  },
  {
    path: 'accounting-ledger',
    loadChildren: () =>
      import('./main/daily-export-transaction/item.module').then(
        m => m.DailyExportTransactionModule
      ),
  },
  {
    path: 'fb-inventory',
    loadChildren: () =>
      import('./main/daily-export-restaurant-pos/item.module').then(
        m => m.DailyExportRestaurantPosModule
      ),
  },
  {
    path: 'sale-revenue-report-night-audit',
    loadChildren: () =>
      import('./main/srrana/item.module').then(
        m => m.SaleRevenueReportNightAuditModule
      ),
  },
  {
    path: 'night-audit-report',
    loadChildren: () =>
      import('./main/night-audit/item.module').then(m => m.NightAuditModule),
  },
  {
    path: 'cash-position',
    loadChildren: () =>
      import('./main/cash-position-summary/item.module').then(
        m => m.CashPositionSummaryModule
      ),
  },
  {
    path: 'payment-gateways',
    loadChildren: () =>
      import('./main/payment-gateways/payment-gateways.module').then(
        m => m.PaymentGatewaysModule
      ),
  },
  {
    path: 'channnel-settings',
    loadChildren: () =>
      import(
        './main/reservations/components/reservation-channel-settings/reservation-channel-settings.module'
      ).then(m => m.ReservationChannelSettingsModule),
  },
  {
    path: 'guest-registration-settings',
    loadChildren: () =>
      import(
        './main/guest-registration-settings/guest-registration-settings.module'
      ).then(m => m.GuestRegistrationSettingsModule),
  },
  {
    path: 'contact-us-settings',
    loadChildren: () =>
      import('./main/contact-us-settings/contact-us-settings.module').then(
        m => m.ContactUsSettingsModule
      ),
  },
  {
    path: 'web-notifications',
    loadChildren: () =>
      import('./main/web-notifications/web-notifications.module').then(
        m => m.WebNotificationsModule
      ),
  },
  {
    path: 'restaurants',
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'restaurants-pos',
            loadChildren: () =>
              import('./main/pos/pos.module').then(m => m.PosModule),
          },
          {
            path: 'restaurants-food-beverage',
            loadChildren: () =>
              import('./main/food-beverage/food-beverage.module').then(
                m => m.FoodBeverageModule
              ),
          },
          {
            path: 'restaurants-inventory-master',
            loadChildren: () =>
              import('./main/inventory-master/inventory-master.module').then(
                m => m.InventoryMasterModule
              ),
          },
          {
            path: 'restaurants-fb-daily-report',
            loadChildren: () =>
              import('./main/fb-daily-report/fb-daily-report.module').then(
                m => m.FBDailyReportModule
              ),
          },
          {
            path: 'food-daily-report',
            loadChildren: () =>
              import(
                './main/category-food-daily-report/category-food-daily-report.module'
              ).then(m => m.CategoryFoodDailyReportModule),
          },
          {
            path: 'beverage-daily-report',
            loadChildren: () =>
              import(
                './main/category-beverage-daily-report/category-beverage-daily-report.module'
              ).then(m => m.CategoryBeverageDailyReportModule),
          },
          {
            path: 'restaurants-table-manager',
            loadChildren: () =>
              import('./main/table-manager/table-manager.module').then(
                m => m.TableManagerModule
              ),
          },
          {
            path: 'fd-pos',
            loadChildren: () =>
              import(
                './main/pos-front-desk/pos-front-desk/pos-front-desk.module'
              ).then(m => m.PosFrontDeskModule),
          },
          {
            path: 'fd-food-beverage',
            loadChildren: () =>
              import(
                './main/pos-front-desk/pos-front-desk-menu/pos-front-desk-menu.module'
              ).then(m => m.PosFrontDeskMenuModule),
          },
          {
            path: 'pos-fd-daily-report',
            loadChildren: () =>
              import('./main/fd-daily-report/fd-daily-report.module').then(
                m => m.FDDailyReportModule
              ),
          },
          /// Tương tự mấy cái menu con
        ],
      },
    ],
  },
  {
    path: 'frontdesk',
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'frontdesk-pos',
            loadChildren: () =>
              import(
                './main/pos-front-desk/pos-front-desk/pos-front-desk.module'
              ).then(m => m.PosFrontDeskModule),
          },
          {
            path: 'frontdesk-food-beverage',
            loadChildren: () =>
              import(
                './main/pos-front-desk/pos-front-desk-menu/pos-front-desk-menu.module'
              ).then(m => m.PosFrontDeskMenuModule),
          },
          {
            path: 'frontdesk-daily-report',
            loadChildren: () =>
              import('./main/fd-daily-report/fd-daily-report.module').then(
                m => m.FDDailyReportModule
              ),
          },
          /// Tương tự mấy cái menu con
        ],
      },
    ],
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
]

const MATERIAL_MODULES = [
  // Material
  MatButtonModule,
  MatIconModule,
  MatPaginatorModule,
  MatToolbarModule,
]

const MODULES = [
  HMPModule,
  FuseProgressBarModule,
  FuseSharedModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
  ChartsModule,
  // LayoutModule,
  // WebSharedModule,
]

const SYST_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  AngularFireDatabaseModule,

  AngularFireAuthModule,
  AngularFireMessagingModule,
  AngularFireModule.initializeApp(environment.firebase),
  AngularFirestoreModule,
  AngularFireStorageModule,
  FuseModule.forRoot(fuseConfig),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  AngularCalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
  NgxPayPalModule,
  // Material moment date module
  MatMomentDateModule,
  // tslint:disable-next-line: deprecation
  QuickLaunchNavigationModule,
  GalleryModule.forRoot(),
  NgxMaterialTimepickerModule.forRoot(),
  ToastrModule.forRoot({
    toastComponent: AppToastComponent,
    maxOpened: 3,
    // positionClass: 'toast-top-center',
    toastClass: 'ngx-toastr app-toast',
  }),
  NgxMaskModule.forRoot(),
]

@NgModule({
  declarations: [
    AppComponent,
    AppToastComponent,
    NgSelectFormFieldControlDirective,
  ],
  imports: [
    ...SYST_MODULES,
    ...MATERIAL_MODULES,
    ...MODULES,
    RouterModule.forRoot(appRoutes),
  ],
  exports: [
    WebSharedModule,
    ExportModalModule,
    NgSelectFormFieldControlDirective,
    RouterModule,
  ],
  entryComponents: [AppToastComponent],
  bootstrap: [AppComponent],
  providers: [
    WebNotificationsService,
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher,
    },
    ThemeService,
  ],
})
export class AppModule {}
