import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Payment } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('PaymentService')

/**
 * Đây là services dành cho Payment
 */

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'payment/payment_entity',
    detail: 'payment/payment_entity',
    payment_reservation_list: 'payment/payment_reservations_list',
    payment_reservationroom_list: 'payment/payment_reservationroom_list',
    export: 'export/export_payment_register',
    list_account_room: 'payment/list_guests_from_reservation_room',
    payment_list_reservations_for_pay:
      'payment/payment_list_reservations_for_pay',
    get_list_reservations_to_pay: 'payment/list_reservations_to_pay',
    payment_list_reservations_for_deposit:
      'payment/payment_list_reservations_for_deposit',
    payment_list_opentab_for_pay: 'payment/payment_list_opentab',
    rooms_of_business: 'payment/rooms_of_business',
    send_mail_receipt: 'reservations/reservations_entity/send_email_receipt',
    send_mail_biling: 'reservations/reservations_entity/send_email_billing',
    paypal: 'payment/payment_entity/paid_paypal_payment',
    list_accounting_codes: 'manager/account_code_entity',
    edit_accounting_codes: 'payment/payment_entity/edit_accounting_codes',
    allUO: 'business/filter_unit_owner',
    allResident: 'business/filter_lt_resident',
    allAgent: 'business/filter_agent',
    new_deposit: 'payment/payment_entity/new_deposit_payment',
    other_payment: 'payment/payment_entity/other_payment',
    reservation_payment: 'payment/payment_entity/reservation_payment',
    reservations_payment:
      'payment/payment_entity/reservation_invoice_generator_payment',
    list_invoice_for_houseledger:
      'payment/payment_list_invoices_of_house_ledger',
    list_invoice_for_house_ledger:
      'payment/list_house_ledger_invoices_generator',
    open_tab_payment: 'payment/payment_entity/opentab_payment',
    return_deposit: 'payment/payment_entity/deposit_return_payment',
    house_ledger_payment: 'payment/payment_entity/house_ledger_payment',
    pay_house_ledger_payment:
      'payment/payment_entity/house_ledger_invoice_generator_payment',
    refund_payment: 'payment/payment_entity/refund_payment',
    submit_refund_payment:
      'payment/payment_entity/refund_invoice_generator_payment',
    void_payment: 'payment/payment_entity/void_payment',
    payment_detail_info: 'payment/payment_reservations_info',
    list_pm_method: 'payment/pay_method_entity',
    hide_tax: 'payment/payment_entity/hidden_tax',
    get_list_combine_invoices:
    'reservations/invoice_generator_list_invoices_to_pay',
    expense_code_type: 'manager/expense_journal_accounting_codes',
    payment_register_accounting_codes: 'payment/payment_register_accounting_codes',
    checkout_ksher: 'public/ksher/checkout',
  }

  /**
   * Lấy tất cả Payment đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[payment_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getListAccountingCodes(account_code?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[ac_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', 'true')
      .set('parameters[ac_number_type]', account_code)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_accounting_codes, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllExpenseCodeType(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.expense_code_type, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  
  getAllPaymentRegisterCodeType(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.payment_register_accounting_codes, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  sendMailReceipt(res): Observable<any> {
    return this.apiService.post(this._apiPath.send_mail_receipt, res).pipe(
      map(data => {
        return data
      })
    )
  }
  sendMailBilling(res): Observable<any> {
    return this.apiService.post(this._apiPath.send_mail_biling, res).pipe(
      map(data => {
        return data
      })
    )
  }
  payPal(res): Observable<any> {
    return this.apiService.post(this._apiPath.paypal, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getReservationForPay(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.payment_list_reservations_for_pay, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getListReservationForPay(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.get_list_reservations_to_pay, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReservationForDeposit(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.payment_list_reservations_for_deposit, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getOpenTabForPay(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.payment_list_opentab_for_pay, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllRoomForPay(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.rooms_of_business, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAccountRoom(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_account_room, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getPaymentReservationRoom(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.payment_reservationroom_list, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getPaymentReservation(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.payment_reservation_list, pa)
      .pipe(
        map(data => {
          //
          return data
        })
      )
  }

  /**
   * Lấy thông tin chi tiết của Payment dưạ vào @id
   * @param Id ID của Payment
   */
  getDetail(id: string): Observable<Payment> {
    return this.apiService.get<Payment>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Payment
   * @param res Thông tin Payment
   */
  create(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }
  create_open_tab(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.open_tab_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }
  create_new_deposit(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.new_deposit, res).pipe(
      map(data => {
        return data
      })
    )
  }
  create_return_deposit(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.return_deposit, res).pipe(
      map(data => {
        return data
      })
    )
  }
  create_other_payment(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.other_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }
  create_reservation_payment(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.reservation_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }
  create_reservations_payment(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.reservations_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }
  create_house_ledger_payment(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.house_ledger_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }
  pay_house_ledger_payment(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService
      .post(this._apiPath.pay_house_ledger_payment, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  create_refund_payment(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.refund_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }
  submit_refund_payment(res: Payment): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.submit_refund_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Payment
   * @param res Thông tin Payment
   */
  createpaymentactivities(res): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }
  /**
   * Chỉnh sửa thông tin Payment
   *
   * @param res Thông tin Payment muốn chỉnh sửa
   */
  edit(res: Payment): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.pid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Payment
   *
   * @param id Xóa Payment bằng Payment ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  export(startDate, endDate): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('start_date', startDate)
      .set('end_date', endDate)
    return this.apiService.get<any>(this._apiPath.export, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  editAccountCodePayment(res): Observable<any> {
    return this.apiService.post(this._apiPath.edit_accounting_codes, res).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllUnitOwner(deposit?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('deposit', deposit)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.allUO, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllAgent(deposit?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('deposit', deposit)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.allAgent, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllResident(deposit?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('deposit', deposit)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.allResident, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getInvoiceHouseLedger(
    uid?: any,
    client_type?: any,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('client_type', client_type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_invoice_for_houseledger, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getInvoiceForHouseLedger(
    uid?: any,
    client_type?: any,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('client_type', client_type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_invoice_for_house_ledger, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  voidPayment(res): Observable<any> {
    return this.apiService.post(this._apiPath.void_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getPaymentInfo(resId: string, params?: any): Observable<any> {
    let pa = new HttpParams().set('rid', resId)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.payment_detail_info, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getListPaymentMethod(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[pm_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_pm_method, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createHideTax(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.hide_tax, res).pipe(
      map(data => {
        return data
      })
    )
  }
  onGetListInvoiceCombinePayment(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.get_list_combine_invoices, res)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  getAllInvoicesOther(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    // let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any[]>(this._apiPath.get_list_combine_invoices, pa)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  checkoutKsher(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.checkout_ksher, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
