import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'

const log = new Logger('GuestStayService')

/**
 * Đây là services dành cho GuestStayService
 */

@Injectable({ providedIn: 'root' })
export class GuestStayService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    list: '',
    rid_roomid: 'reservations/reservations_guest_stay',
    detail: 'reservations/reservations_guest_stay',
    add: 'reservations/reservations_guest_stay/add_guest_stay',
    update: 'reservations/reservations_guest_stay/update_guest_stay',
    search_passport_id: 'reservations/reservations_search_guest_stay',
    check_in_form_submit: 'guest/guest_checkin_information',
  }

  getGuestByRerservationRoom(rid, roomid, params?: any): Observable<any[]> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set('rid', rid).set('room_id', roomid)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.rid_roomid, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService.get<any>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  getDetailByPassportId(bid, id: string, type?: string): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('search_text', id)
      .set('search_type', type)
    return this.apiService
      .get<any[]>(`${this._apiPath.search_passport_id}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res: any): Observable<any> {
    res.gtid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(gtid, res: any): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${gtid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  checkInFormAdd(res): Observable<any> {
    return this.apiService.post(this._apiPath.check_in_form_submit, res).pipe(
      map(data => {
        return data
      })
    )
  }

  checkInFormEdit(gtid, res: any): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.check_in_form_submit}/${gtid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
