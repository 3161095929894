import { FuseNavigation } from '@fuse/types'
import { RoleActionsData } from '@hmp/services'

export const mainNavigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: 'Main',
    translate: 'Main',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboards',
        translate: 'Dashboards',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Dashboards.svg',
        url: '/dashboard',
        roles: {
          entity: 'dashboard_fd',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },

      {
        id: 'purchase',
        title: 'Purchase',
        translate: 'Purchase',
        type: 'item',
        svg: 'assets/icons/hmp-web/light/purchase.svg',
        roles: {
          entity: 'purchase_entity',
          action: RoleActionsData.View,
        },
        url: '/purchase',
      },
      // {
      //   id: "posmaster",
      //   title: "POS Master",
      //   translate: "POS Master",
      //   type: "item",
      //   svg: "assets/icons/hmp-web/new-icon/pos-master.svg",
      //   url: "/#"
      // },
    ],
  },
]

export const navigationClient: FuseNavigation[] = [
  {
    id: 'client',
    title: 'Client',
    translate: 'Client',
    type: 'group',
    children: [
      {
        id: 'client',
        title: 'Reservations',
        roles: {
          entity: 'reservations_entity',
          action: RoleActionsData.View,
        },
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Reservations.svg',
        url: '/client',
      },
      {
        id: 'profile',
        title: 'Profile',
        translate: 'Profile',
        type: 'item',
        svg: 'assets/icons/hmp-web/light/profile.svg',
        url: '/profile',
        roles: {
          entity: 'user_profile',
          action: RoleActionsData.View,
        },
      },
    ],
  },
]

export const navigationFrontdesk: FuseNavigation[] = [
  {
    id: 'frontdesk',
    title: 'Front Desk',
    translate: 'Front Desk',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboards',
        translate: 'Dashboards',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Dashboards.svg',
        url: '/dashboard',
        roles: {
          entity: 'dashboard_fd',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'calendar',
        title: 'Calendar',
        translate: 'Calendar',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Calendar.svg',
        url: '/calendar',
        exactMatch: true,
        roles: {
          entity: 'reservation_calendar',
          action: RoleActionsData.View,
        },
      },
      {
        id: 'reservations',
        title: 'Reservations',
        translate: 'Reservations',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Reservations.svg',
        url: '/reservations',
        roles: {
          entity: 'reservations_entity',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'guestfolio',
        title: 'Guest Folio',
        translate: 'Guest Folio',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Guest-Folio.svg',
        url: '/guestfolio',
        roles: {
          entity: 'guestfolio',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'listnewactivities',
        title: 'Activity Scheduler',
        translate: 'Activity Scheduler',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Activity-Scheduler.svg',
        url: '/activity',
        roles: {
          entity: 'activity_scheduler',
          action: RoleActionsData.View,
        },

        exactMatch: true,
      },
      {
        id: 'rooms_lists',
        title: 'Rooms Register',
        translate: 'Rooms Register',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Rooms-Register.svg',
        url: '/rooms',
        roles: {
          entity: 'room_entity',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'web-notifications',
        title: 'Web Notifications',
        translate: 'Web Notifications',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/web-notification.svg',
        url: '/web-notifications',
        roles: {
          entity: 'web_notifications',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
    ],
  },
]

export const navigationPosCenter: FuseNavigation[] = [
  {
    id: 'navigationPosCenter',
    title: '',
    translate: '',
    type: 'group',

    children: [
      {
        id: 'Restaurants_POS',
        title: 'Restaurants POS',
        translate: 'Restaurants POS',
        type: 'group',
        svg: 'assets/icons/hmp-web/new-icon/White-Restaurant-POS.svg',
        children: [
          {
            id: 'foodbeverage',
            title: 'Restaurant POS',
            translate: 'Restaurant POS',
            type: 'collapsable',
            svg: 'assets/icons/hmp-web/new-icon/White-Restaurant-POS.svg',
            // roles: {
            //   entity: 'fb_entity',
            //   action: RoleActionsData.View,
            // },
            children: [
              {
                id: 'pos',
                title: 'Orders',
                translate: 'Orders',
                type: 'item',
                svg: 'assets/icons/hmp-web/new-icon/Orders.svg',
                url: '/pos',
                roles: {
                  entity: 'pos',
                  action: RoleActionsData.View,
                },
                // exactMatch: true,
              },
              {
                id: 'menu',
                title: 'Menu',
                translate: 'Menu',
                type: 'item',
                url: '/food-beverage',
                roles: {
                  entity: 'fb_management',
                  action: RoleActionsData.Create,
                },
                svg: 'assets/icons/hmp-web/new-icon/Menu.svg',
                exactMatch: true,
              },
              {
                id: 'recipemanagaer',
                title: 'Recipes',
                translate: 'Recipes',
                type: 'item',
                url: '/inventory-master',
                roles: {
                  entity: 'inventorymaster_entity',
                  action: RoleActionsData.View,
                },
                svg: 'assets/icons/hmp-web/new-icon/Recipes.svg',
                // exactMatch: true,
              },
              {
                id: 'table-manager',
                title: 'Tables',
                translate: 'Tables',
                type: 'item',
                url: '/table-manager',
                roles: {
                  entity: 'table_entity',
                  action: RoleActionsData.View,
                },
                svg: 'assets/icons/hmp-web/new-icon/Tables.svg',
              },
              {
                id: 'fb-daily-report',
                title: 'Daily Report',
                translate: 'Daily Report',
                type: 'item',
                url: '/fb-daily-report',
                roles: {
                  entity: 'restaurant_daily_report',
                  action: RoleActionsData.View,
                },
                svg: 'assets/icons/hmp-web/new-icon/daily-report.svg',
              },
              // {
              //   id: 'category-food-daily-report',
              //   title: 'Food Daily Report',
              //   translate: 'Food Daily Report',
              //   type: 'item',
              //   url: '/category-food-daily-report',
              //   roles: {
              //     entity: 'restaurant_daily_report',
              //     action: RoleActionsData.View,
              //   },
              //   svg: 'assets/icons/hmp-web/new-icon/food-daily-report.svg',
              // },
              // {
              //   id: 'category-beverage-daily-report',
              //   title: 'Beverage Daily Report',
              //   translate: 'Beverage Daily Report',
              //   type: 'item',
              //   url: '/category-beverage-daily-report',
              //   roles: {
              //     entity: 'restaurant_daily_report',
              //     action: RoleActionsData.View,
              //   },
              //   svg: 'assets/icons/hmp-web/new-icon/beverage-daily-report.svg',
              // },
              // {
              //   id: 'printer-settings',
              //   title: 'Pinter Settings',
              //   translate: 'Printer Settings',
              //   type: 'item',
              //   svg: 'assets/icons/hmp-web/new-icon/printer.svg',
              //   url: '/printer-settings',
              //   roles: {
              //     // entity: 'printer_settings',
              //     action: RoleActionsData.View,
              //   },
              //   exactMatch: true,
              // },
            ],
          },
        ],
      },
      // {
      //   id: 'font-desk-activities',
      //   title: 'FONT DESK ACTIVITIES',
      //   translate: 'FONT DESK ACTIVITIES',
      //   type: 'group',
      //   svg: 'assets/icons/hmp-web/new-icon/White-Restaurant-POS.svg',
      //   children: [
      //     {
      //       id: 'font-desk-excursions',
      //       title: 'Excursions',
      //       translate: 'Excursions',
      //       type: 'item',
      //       svg: 'assets/icons/hmp-web/new-icon/manager_res.svg',
      //       roles: {
      //         entity: 'restaurant_manager',
      //         action: RoleActionsData.View,
      //       },
      //       url: '/font-desk-excursions',
      //     },
      //   ],
      // },

      {
        id: 'fd_POS',
        title: 'Front Desk POS',
        translate: 'Front Desk POS',
        type: 'group',
        svg: 'assets/icons/hmp-web/new-icon/White-Restaurant-POS.svg',
        children: [
          {
            id: 'posmaster',
            title: 'Front Desk POS',
            translate: 'Front Desk POS',
            type: 'collapsable',
            svg: 'assets/icons/hmp-web/new-icon/White-Front-Desk-POS.svg',
            children: [
              {
                id: 'pos-front-desk-order',
                title: 'Orders',
                translate: 'Orders',
                type: 'item',
                url: '/order_outlets',
                svg: 'assets/icons/hmp-web/order.svg',
                roles: {
                  entity: 'fd_pos_order',
                  action: RoleActionsData.View,
                },
              },
              {
                id: 'pos-front-desk-menu',
                title: 'Menu',
                translate: 'Menu',
                type: 'item',
                url: '/menu_outlets',
                svg: 'assets/icons/hmp-web/new-icon/Menu.svg',
                roles: {
                  entity: 'fd_pos_menu',
                  action: RoleActionsData.View,
                },
                // exactMatch: true,
              },
              {
                id: 'fd-daily-report',
                title: 'Daily Report',
                translate: 'Daily Report',
                type: 'item',
                url: '/fd-daily-report',
                roles: {
                  entity: 'front_desk_daily_report',
                  action: RoleActionsData.View,
                },
                svg: 'assets/icons/hmp-web/new-icon/daily-report.svg',
              },
            ],
          },
          {
            id: 'front-desk-excursions',
            title: 'Excursions',
            translate: 'Excursions',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Excursions.svg',
            roles: {
              entity: 'restaurant_manager',
              action: RoleActionsData.View,
            },
            url: '/front-desk-excursions',
          },
          {
            id: 'front-desk-transfers',
            title: 'Transfers',
            translate: 'Transfers',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Transfers.svg',
            roles: {
              entity: 'restaurant_manager',
              action: RoleActionsData.View,
            },
            url: '/front-desk-transfers',
          },
        ],
      },
      {
        id: 'manager',
        title: 'Manager',
        translate: 'Manager',
        type: 'group',
        svg: 'assets/icons/hmp-web/new-icon/White-Restaurant-POS.svg',
        children: [
          {
            id: 'restaurant',
            title: 'Restaurants',
            translate: 'Restaurants',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/manager_res.svg',
            roles: {
              entity: 'restaurant_manager',
              action: RoleActionsData.View,
            },
            url: '/restaurant-manager',
          },
          {
            id: 'frontdesk',
            title: 'Front Desks',
            translate: 'Front Desks',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/manager_res.svg',
            roles: {
              entity: 'front_desk_manager',
              action: RoleActionsData.View,
            },
            url: '/frontdesk-manager',
          },
        ],
      },
    ],
  },
]

export const navigationCityLedger: FuseNavigation[] = [
  {
    id: 'navigationCityLedger',
    title: 'City Ledger',
    translate: 'City Ledger',
    type: 'group',
    children: [
      {
        id: 'ar-register',
        title: 'A/R Register',
        translate: 'A/R Register',
        type: 'item',
        roles: {
          entity: 'ar_register',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/ar-register.svg',
        url: '/ar-register',
      },
      {
        id: 'posmaster',
        title: 'Unit Owners',
        translate: 'Unit Owners',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/unit-owners.svg',
        // roles: {
        //   entity: 'fd_pos',
        //   action: RoleActionsData.View,
        // },
        children: [
          {
            id: 'unit-owner-folio',
            title: 'Unit Owner Folio',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Unit-Owner-Folio.svg',
            url: '/unit-owner-folio',
            roles: {
              entity: 'unit_owner_folio',
              action: RoleActionsData.View,
            },
            // exactMatch: true,
          },
          {
            id: 'unit-owner-operation-report',
            title: 'Unit Owner Ops Report',
            translate: 'Unit Owner Ops Report',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/P&L-1.svg',
            roles: {
              entity: 'unit_owner_operations_report',
              action: RoleActionsData.View,
            },
            url: '/reports/unit-owner-operation',
          },
        ],
      },
      {
        id: 'resident-folio',
        title: 'Resident Folio',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Resident-Folio.svg',
        url: '/resident-folio',
        roles: {
          entity: 'resident_folio',
          action: RoleActionsData.View,
        },
        // exactMatch: true,
      },
      {
        id: 'dynamic-invoice-generator',
        title: 'Invoice Generator',
        translate: 'Invoice Generator',
        type: 'item',
        roles: {
          entity: 'invoice_generator',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/dynamic-invoice-general.svg',
        url: '/dynamic-invoice-generator',
      },
    ],
  },
]

export const navigationManager: FuseNavigation[] = [
  {
    id: 'navigationManager',
    title: 'Manager',
    translate: 'Manager',
    type: 'group',
    children: [
      {
        id: 'event-planner',
        title: 'Events Calendar',
        type: 'item',
        roles: {
          entity: 'event_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Events-Calendar.svg',
        url: '/event-planner',
      },
      {
        id: 'task-manager',
        title: 'Task Manager',
        type: 'item',
        roles: {
          entity: 'task_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Task-Manager.svg',
        url: '/task-manager',
      },
      {
        id: 'planner',
        title: 'Business Planner',
        type: 'item',
        roles: {
          entity: 'planner_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Business-Planner.svg',
        url: '/planner',
      },
      {
        id: 'fixedasset',
        title: 'Fixed Assets Database',
        translate: 'Fixed Assets Database',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Fixed-Assets-Database.svg',
        roles: {
          entity: 'assets_entity',
          action: RoleActionsData.View,
        },
        url: '/asset',
      },
      {
        id: 'asset-owner',
        title: 'Asset Owners',
        type: 'item',
        roles: {
          entity: 'asset_owner_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Asset-Owners.svg',
        url: '/asset-owner',
      },
      {
        id: 'suppliers',
        title: 'Suppliers',
        translate: 'Suppliers',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Suppliers.svg',
        roles: {
          entity: 'supplier_entity',
          action: RoleActionsData.View,
        },
        url: '/supplier',
      },
      {
        id: 'vendor',
        title: 'Vendors',
        translate: 'Vendors',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Vendors.svg',
        roles: {
          entity: 'vendor_entity',
          action: RoleActionsData.View,
        },
        url: '/vendor',
      },
    ],
  },
]

export const navigationOps: FuseNavigation[] = [
  {
    id: 'navigationOps',
    title: 'Staff Ops',
    translate: 'Staff Ops',
    type: 'group',
    children: [
      {
        id: 'notice',
        title: 'Staff Notice Board',
        translate: 'Staff Notice Board',
        type: 'item',
        url: '/notice',
        svg: 'assets/icons/hmp-web/new-icon/Staff-Notice-Board.svg',
        roles: {
          entity: 'notification_entity',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'chats',
        title: 'Staff Chat',
        translate: 'Staff Chat',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Staff-Chat.svg',
        url: '/chats',
        roles: {
          entity: 'business_chat',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'housekeeping',
        title: 'Housekeeping',
        translate: 'Housekeeping',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Housekeeping.svg',
        roles: {
          entity: 'housekeeping_entity',
          action: RoleActionsData.View,
        },
        url: '/housekeeping',
      },
      {
        id: 'maintenance',
        title: 'Maintenance',
        translate: 'Maintenance',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Maintenance.svg',
        roles: {
          entity: 'maintenance_entity',
          action: RoleActionsData.View,
        },
        url: '/maintenances',
        exactMatch: true,
      },
      {
        id: 'hr',
        title: 'HR',
        translate: 'HR',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/White-HR.svg',
        roles: {
          entity: 'hr',
          action: RoleActionsData.View,
        },
        children: [
          {
            id: 'roster',
            title: 'Roster',
            translate: 'Roster',
            type: 'item',
            url: '/hr',
            svg: 'assets/icons/hmp-web/new-icon/Roster-1.svg',
            roles: {
              entity: 'hr',
              action: RoleActionsData.Create,
            },
            exactMatch: true,
          },
          {
            id: 'scheduler',
            title: 'Scheduler',
            translate: 'Scheduler',
            type: 'item',
            url: '/schedule',
            svg: 'assets/icons/hmp-web/new-icon/Scheduler.svg',
            roles: {
              entity: 'schedule_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
        ],
      },
    ],
  },
]

export const navigationSalesMarketing: FuseNavigation[] = [
  {
    id: 'navigationSalesMarketing',
    title: 'Sales & Marketing',
    translate: 'Sales & Marketing',
    type: 'group',
    children: [
      {
        id: 'salescalls',
        title: 'Sales Outreach',
        translate: 'Sales Outreach',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Sales-Outreach.svg',
        roles: {
          entity: 'salescalls_entity',
          action: RoleActionsData.View,
        },
        url: '/salescalls',
        exactMatch: true,
      },
      {
        id: 'salescallscalendar',
        title: 'Sales Calendar',
        translate: 'Sales Calendar',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Sales-Calendar.svg',
        roles: {
          entity: 'salescalls_todo_calendar',
          action: RoleActionsData.View,
        },
        url: '/salescalls/calendar',
        // exactMatch: true,
      },
      {
        id: 'guests',
        title: 'Guest Database',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Guest-Database.svg',
        url: '/guests',
        roles: {
          entity: 'guests',
          action: RoleActionsData.View,
        },
        // exactMatch: true,
      },
      {
        id: 'agent_manager',
        title: 'Agent Database',
        translate: 'Agents Database',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Agents-Database.svg',
        url: '/agent-manager',
        roles: {
          entity: 'agents',
          action: RoleActionsData.View,
        },
        // exactMatch: true,
      },
      {
        id: 'discount-promotions',
        title: 'Promotions',
        translate: 'Promotions',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Promotions.svg',
        url: '/promotions',
        roles: {
          entity: 'discount_entity',
          action: RoleActionsData.View,
        },
        // exactMatch: true,
      },
      {
        id: 'digitalasssets',
        title: 'Marketing Resources',
        translate: 'Marketing Resources',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Marketing-Resources.svg',
        roles: {
          entity: 'digital_entity',
          action: RoleActionsData.View,
        },
        url: '/digital-assets',
        // exactMatch: true,
      },
    ],
  },
]

export const navigationFinance: FuseNavigation[] = [
  {
    id: 'navigationFinance',
    title: 'Finance & Reporting',
    translate: 'Finance & Reporting',
    type: 'group',
    children: [
      {
        id: 'paymentregister',
        title: 'Payment Register',
        translate: 'Payment Register',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Payment-Register.svg',
        roles: {
          entity: 'payment_entity',
          action: RoleActionsData.View,
        },

        url: '/payment-register',
      },
      {
        id: 'expense-journal',
        title: 'Expense Journal',
        translate: 'Expense Journal',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Expense-Journal.svg',
        roles: {
          entity: 'expense_journal_entity',
          action: RoleActionsData.View,
        },
        url: '/expense-journal',
      },
      {
        id: 'service-charge-register',
        title: 'Service Charge Register',
        translate: 'Service Charge Register',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/service-charge-register.svg',
        roles: {
          entity: 'service_charge_register',
          action: RoleActionsData.View,
        },
        url: '/service-charge-register',
      },
      {
        id: 'financing-journal',
        title: 'Financing Journal',
        translate: 'Financing Journal',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Financing-Journal.svg',
        roles: {
          entity: 'financing_journal_entity',
          action: RoleActionsData.View,
        },
        url: '/financing-journal',
      },
      {
        id: 'forecast_wizard',
        title: 'Forecast Wizard',
        translate: 'Forecast Wizard',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Forecast-Wizard.svg',
        roles: {
          entity: 'forecast_wizard',
          action: RoleActionsData.View,
        },
        url: '/forecast-wizard',
      },
      {
        id: 'sales-reports',
        title: 'Sales Reports',
        translate: 'Sales Reports',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/White-Sales-Reports.svg',
        children: [
          // {
          //   id: 'sale_revenue_report_night_audit',
          //   title: 'Revenue & Night Audit',
          //   translate: 'Revenue & Night Audit',
          //   type: 'item',
          //   svg: 'assets/icons/hmp-web/new-icon/Package-Sales.svg',
          //   url: '/sale-revenue-report-night-audit',
          //   roles: {
          //     entity: 'sales_revenue_report',
          //     action: RoleActionsData.View,
          //   },
          //   exactMatch: true,
          // },
          {
            id: 'night_audit_report',
            title: 'Night Audit',
            translate: 'Night Audit',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Package-Sales.svg',
            url: '/night-audit-report',
            roles: {
              entity: 'revenue_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'dailyrevenuereport',
            title: 'Revenue',
            translate: 'Revenue',
            type: 'item',
            roles: {
              entity: 'daily_revenue_report',
              action: RoleActionsData.View,
            },
            svg: 'assets/icons/hmp-web/new-icon/daily-revenue-report.svg',
            url: '/revenue-report',
          },
          {
            id: 'dailypaymentgatewayreport',
            title: 'Payment Gateway Records',
            translate: 'Payment Gateway Records',
            type: 'item',
            roles: {
              entity: 'daily_revenue_report',
              action: RoleActionsData.View,
            },
            svg:
              'assets/icons/hmp-web/new-icon/daily-payment-gateway-report.svg',
            url: '/payment-gateway-records',
          },
          {
            id: 'daily-export-transaction',
            title: 'Accounting Ledger',
            translate: 'Accounting Ledger',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Daily-Transactions.svg',
            roles: {
              entity: 'daily_transactions',
              action: RoleActionsData.View,
            },
            url: '/accounting-ledger',
            exactMatch: true,
          },
          {
            id: 'fb-inventory',
            title: 'F&B Inventory',
            translate: 'F&B Inventory',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Daily-Restaurant-Pos.svg',
            roles: {
              entity: 'daily_restaurant_pos',
              action: RoleActionsData.View,
            },
            url: '/fb-inventory',
            exactMatch: true,
          },
          {
            id: 'reservationreport',
            title: 'Reservations',
            translate: 'Reservations',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/reservation-report.svg',
            url: '/reservation-report',
            roles: {
              entity: 'reservation_reports',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'production-reports',
            title: 'Production',
            translate: 'Production',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Production-Reports.svg',
            roles: {
              entity: 'production_reports',
              action: RoleActionsData.View,
            },
            url: '/production-reports',
            exactMatch: true,
          },
          {
            id: 'report-package-sales',
            title: 'Package Sales',
            translate: 'Package Sales',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Package-Sales.svg',
            roles: {
              entity: 'package_sales_report',
              action: RoleActionsData.View,
            },
            url: '/report-package-sales',
            exactMatch: true,
          },
        ],
      },
      {
        id: 'reports',
        title: 'Business Reports',
        translate: 'Business Reports',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/White-Business Reports.svg',
        children: [
          // {
          //   id: "balance-sheet",
          //   title: "Balance Sheet",
          //   translate: "Balance Sheet",
          //   type: "item",
          //   svg: "assets/icons/hmp-web/new-icon/reports.svg",
          //   roles: {
          //     entity: "report_balance_sheet",
          //     action: RoleActionsData.View
          //   },
          //   url: "/reports/balance-sheet"
          // },
          {
            id: 'pl',
            title: 'P&L',
            translate: 'P&L',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/P&L-1.svg',
            roles: {
              entity: 'report_pl',
              action: RoleActionsData.View,
            },
            url: '/reports/p-l',
          },

          // {
          //   id: 'budget-report',
          //   title: 'Budget',
          //   translate: 'Budget',
          //   type: 'item',
          //   svg: 'assets/icons/hmp-web/new-icon/Budget-1.svg',
          //   roles: {
          //     entity: 'report_budget',
          //     action: RoleActionsData.View,
          //   },
          //   url: '/reports/budget',
          // },
          {
            id: 'key-indicators-report',
            title: 'Key Indicators',
            translate: 'Key Indicators',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Budget-1.svg',
            roles: {
              entity: 'report_budget',
              action: RoleActionsData.View,
            },
            url: '/reports/key-indicators-report',
          },
          {
            id: 'budget-generator-report',
            title: 'Budget',
            translate: 'Budget',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Budget-1.svg',
            roles: {
              entity: 'report_budget',
              action: RoleActionsData.View,
            },
            url: '/reports/budget',
          },
          {
            id: 'b2a-report',
            title: 'B2A',
            translate: 'B2A',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/B-1.svg',
            roles: {
              entity: 'report_b2a',
              action: RoleActionsData.View,
            },
            url: '/reports/b2a',
          },
          {
            id: 'bob-report',
            title: 'BoB & Pickup',
            translate: 'BoB & Pickup',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/BoB&Pickup.svg',
            roles: {
              entity: 'report_bob',
              action: RoleActionsData.View,
            },
            url: '/reports/bob',
          },
          {
            id: 'variance',
            title: 'Variance',
            translate: 'Variance',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Variance-Report.svg',
            roles: {
              entity: 'variance_report',
              action: RoleActionsData.View,
            },
            url: '/reports/variance',
          },
          {
            id: 'occupancy-on-the-books',
            title: 'Occupancy On the Books',
            translate: 'Occupancy On the Books',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Occupancy-on-the-books.svg',
            roles: {
              entity: 'occupancy_on_the_books',
              action: RoleActionsData.View,
            },
            url: '/reports/occupancy-on-the-books',
          },
          {
            id: 'custom-guest-reports',
            title: 'Custom Guest Reports',
            translate: 'Custom Guest Reports',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/custom-guest-reports.svg',
            roles: {
              entity: 'custom_guest_reports',
              action: RoleActionsData.View,
            },
            url: '/reports/custom-guest-reports',
          },
        ],
      },
      {
        id: 'other-reports',
        title: 'Other Reports',
        translate: 'Other Reports',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/other-report.svg',
        children: [
          {
            id: 'statutory-report',
            title: 'Statutory',
            translate: 'Statutory',
            type: 'item',
            roles: {
              entity: 'statutory_report',
              action: RoleActionsData.View,
            },
            svg: 'assets/icons/hmp-web/new-icon/staturory_report.svg',
            url: '/statutory-report',
          },
          {
            id: 'report-generator',
            title: 'Operations',
            translate: 'Operations',
            type: 'item',
            roles: {
              entity: 'report_generator',
              action: RoleActionsData.View,
            },
            svg: 'assets/icons/hmp-web/new-icon/Operations-Report.svg',
            url: '/report-generator',
          },
          {
            id: 'report-statement-cash',
            title: 'Cash Flow',
            translate: 'Cash Flow',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Cash-Flow.svg',
            roles: {
              entity: 'cash_flow',
              action: RoleActionsData.View,
            },
            url: '/report-statement-cash',
          },
          {
            id: 'cash-position',
            title: 'Cash Position',
            translate: 'Cash Position',
            type: 'item',
            url: '/cash-position',
            svg: 'assets/icons/hmp-web/new-icon/cash-position-summary.svg',
            roles: {
              entity: 'cash_position',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'net-fixed-assets',
            title: 'Net Fixed Assets',
            translate: 'Net Fixed Assets',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Net-Fixed-Assets.svg',
            roles: {
              entity: 'net_fixed_assets_report',
              action: RoleActionsData.View,
            },
            url: '/reports/fixed-asset',
          },
        ],
      },
    ],
  },
]

export const navigationBudgetBuilder: FuseNavigation[] = [
  {
    id: 'navigationBudgetBuilder',
    title: 'PROJECT DEVELOPER',
    translate: 'PROJECT DEVELOPER',
    type: 'group',
    children: [
      {
        id: 'budget-builder',
        title: 'Budget Builder',
        translate: 'Budget Builder',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/budget-builder.svg',
        url: '/budget-builder',
        roles: {
          entity: 'budget_builder',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'transactionRegister',
        title: 'Transaction Register',
        translate: 'Transaction Register',
        type: 'item',
        url: '/transaction-register',
        svg: 'assets/icons/hmp-web/new-icon/transaction_register.svg',
        roles: {
          entity: 'transaction_register',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
    ],
  },
]

export const navigationTravelX: FuseNavigation[] = [
  {
    id: 'navigationTravelX',
    title: 'TravelX Organizer',
    translate: 'TravelX Organizer',
    type: 'group',
    children: [
      {
        id: 'guest-contact-center',
        title: 'Guest Contact Center',
        translate: 'Guest Contact Center',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Guest-Contact-Center.svg',
        url: '/guest-contact-center',
        roles: {
          entity: 'contact_center_entity',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'guest-letter',
        title: 'Guest Letters',
        translate: 'Guest Letters',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Guest-Letters.svg',
        url: '/guest-letter',
        roles: {
          entity: 'guest_letters',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'bulletin',
        title: 'Guest Bulletin Board',
        translate: 'Guest Bulletin Board',
        type: 'item',
        url: '/bulletin',
        svg: 'assets/icons/hmp-web/new-icon/Guest-Bulletin-Board.svg',
        // exactMatch: true,
        roles: {
          entity: 'guest_bulletin_entity',
          action: RoleActionsData.View,
        },
      },
    ],
  },
]

export const navigationDirectBookingSetting: FuseNavigation[] = [
  {
    id: 'navigationDirectBookingSetting',
    title: 'Direct Organizer',
    translate: 'Direct Organizer',
    type: 'group',
    children: [
      {
        id: 'settings',
        title: 'Booking Engine Settings',
        translate: 'Booking Engine Settings',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Booking-Engine-Settings.svg',
        url: '/direct-booking/settings',
        roles: {
          entity: 'general_settings',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'customize',
        title: 'Booking Engine Customize',
        type: 'item',
        roles: {
          entity: 'general_settings',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/direct-booking-customize.svg',
        url: '/direct-booking/customize',
      },
      {
        id: 'db_packages',
        title: 'Special Offers',
        type: 'item',
        roles: {
          entity: 'db_packages',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Special-Offers.svg',
        url: '/direct-booking-setting/direct-booking-packages',
      },
      {
        id: 'common_pages',
        title: 'Information Resources',
        type: 'item',
        roles: {
          entity: 'common_pages',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Information-Resources.svg',
        url: '/direct-booking-setting/common-pages',
      },
      {
        id: 'contact-us-settings',
        title: 'Contact Us Settings',
        translate: 'Contact Us Settings',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/contact-us-settings.svg',
        url: '/contact-us-settings',
        roles: {
          entity: 'contact_us_settings',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
    ],
  },
]

export const navigationOTA: FuseNavigation[] = [
  {
    id: 'navigationOTA',
    title: 'OTA Optimizer',
    translate: 'OTA Optimizer',
    type: 'group',
    children: [
      {
        id: 'ota-calendar',
        title: 'OTA Optimizer',
        type: 'item',
        roles: {
          entity: 'ota_calendar',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/OTA-Calendar.svg',
        url: '/optimizer/otacalendar',
      },
    ],
  },
]

export const navigationWeb: FuseNavigation[] = [
  {
    id: 'navigationWeb',
    title: 'Website Organizer',
    translate: 'Website Organizer',
    type: 'group',
    children: [
      {
        id: 'website-settings',
        title: 'Website Settings',
        translate: 'Website Settings',
        type: 'item',
        url: '/website-settings',
        svg: 'assets/icons/hmp-web/new-icon/web-settings.svg',
        roles: {
          entity: 'website_settings_entity',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'website_customize',
        title: 'Website Customize',
        translate: 'Website Customize',
        type: 'item',
        url: '/website-customize',
        svg: 'assets/icons/hmp-web/new-icon/web-customize.svg',
        roles: {
          entity: 'website_entity',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
    ],
  },
]

export const navigationSettings: FuseNavigation[] = [
  {
    id: 'navigationSettings',
    title: 'Settings',
    translate: 'Settings',
    type: 'group',
    children: [
      {
        id: 'hotelinfo',
        title: 'Hotel Info',
        type: 'item',
        roles: {
          entity: 'business_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Hotel-Info.svg',
        url: '/hotel',
      },
      {
        id: 'room-rates',
        title: 'Room Details',
        translate: 'Room Details',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/White-Room-Details.svg',
        children: [
          {
            id: 'agent_class_lists',
            title: 'Agent Classes',
            translate: 'Agent Classes',
            type: 'item',
            url: '/agent-class',
            svg: 'assets/icons/hmp-web/new-icon/Agent-Classes.svg',
            roles: {
              entity: 'agent_class_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'seasons_lists',
            title: 'Seasons',
            translate: 'Seasons',
            type: 'item',
            url: '/seasons',
            svg: 'assets/icons/hmp-web/new-icon/Seasons.svg',
            roles: {
              entity: 'season_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'roomtypes_lists',
            title: 'Room Types',
            translate: 'Room Types',
            type: 'item',
            url: '/roomtypes',
            svg: 'assets/icons/hmp-web/new-icon/Room-Types.svg',
            roles: {
              entity: 'room_type_entity',
              action: RoleActionsData.View,
            },
            // exactMatch: true,
          },
          {
            id: 'extra_amenities',
            title: 'Extra Charges',
            translate: 'Extra Charges',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Extra-Amenities.svg',
            roles: {
              entity: 'extra_amenities_entity',
              action: RoleActionsData.View,
            },
            url: '/extra-amenities',
          },
          {
            id: 'rate-code',
            title: 'Rate Code Settings',
            translate: 'Rate Code Settings',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Extra-Amenities.svg',
            roles: {
              entity: 'ratecode_entity',
              action: RoleActionsData.View,
            },
            url: '/rate-code',
          },
        ],
      },
      {
        id: 'extras',
        title: 'Services',
        translate: 'Services',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/Services.svg',
        children: [
          {
            id: 'packages',
            title: 'Packages',
            translate: 'Packages',
            type: 'item',
            svg: 'assets/icons/hmp-web/new-icon/Packages.svg',
            url: '/packages',
            roles: {
              entity: 'packages_entity',
              action: RoleActionsData.View,
            },
          },
          {
            id: 'excursion',
            title: 'Excursions',
            translate: 'Excursions',
            type: 'item',
            url: '/activity/excursion',
            svg: 'assets/icons/hmp-web/new-icon/Excursions.svg',
            roles: {
              entity: 'excursion_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'transfer',
            title: 'Transfers',
            translate: 'Transfers',
            type: 'item',
            url: '/activity/transfer',
            svg: 'assets/icons/hmp-web/new-icon/Transfers.svg',
            roles: {
              entity: 'transfer_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
        ],
      },
      {
        id: 'taxes',
        title: 'Taxes',
        type: 'item',
        roles: {
          entity: 'taxes_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/Taxes.svg',
        url: '/taxes',
      },
      {
        id: 'service-charges',
        title: 'Service Charges',
        type: 'item',
        roles: {
          entity: 'servicecharge_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/service-charge.svg',
        url: '/service-charges',
      },
      {
        id: 'accounting-codes',
        title: 'Accounting Codes',
        type: 'item',
        roles: {
          entity: 'account_code_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/accounting-codes.svg',
        url: '/accounting-codes',
      },
      {
        id: 'payment-method',
        title: 'Payment Method',
        type: 'item',
        roles: {
          entity: 'pay_method_entity',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/accounting-codes.svg',
        url: '/payment-method',
      },
      {
        id: 'finance',
        title: 'Finance',
        translate: 'Finance',
        type: 'collapsable',
        svg: 'assets/icons/hmp-web/new-icon/White-Finance.svg',
        children: [
          // {
          //   id: 'budgetbuilder',
          //   title: 'Budget Builder',
          //   translate: 'Budget Builder',
          //   type: 'item',
          //   url: '/budget-builder',
          //   svg: 'assets/icons/hmp-web/new-icon/Asset-Types.svg',
          //   roles: {
          //     entity: 'asset_types_entity',
          //     action: RoleActionsData.View,
          //   },
          //   exactMatch: true,
          // },
          {
            id: 'assettype',
            title: 'Asset Types',
            translate: 'Asset Types',
            type: 'item',
            url: '/assettype',
            svg: 'assets/icons/hmp-web/new-icon/Asset-Types.svg',
            roles: {
              entity: 'asset_types_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'revenue',
            title: 'Revenue Types',
            translate: 'Revenue Types',
            type: 'item',
            url: '/revenue',
            svg: 'assets/icons/hmp-web/new-icon/Revenue-Types.svg',
            roles: {
              entity: 'revenue_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'expense',
            title: 'Expense Types',
            translate: 'Expense Types',
            type: 'item',
            url: '/expense',
            svg: 'assets/icons/hmp-web/new-icon/Expense-Types.svg',
            roles: {
              entity: 'expense_entity',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
          {
            id: 'cash-position-settings',
            title: 'Cash Position Settings',
            translate: 'Cash Position Settings',
            type: 'item',
            url: '/cash-position/setting',
            svg: 'assets/icons/hmp-web/new-icon/cash-position-summary.svg',
            roles: {
              entity: 'cash_position',
              action: RoleActionsData.View,
            },
            exactMatch: true,
          },
        ],
      },
      {
        id: 'activity-type',
        title: 'Sales Outreach Types',
        translate: 'Sales Outreach Types',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Sales-Outreach-Types.svg',
        roles: {
          entity: 'sales_outreach_types',
          action: RoleActionsData.View,
        },
        url: '/activity-type',
      },
      {
        id: 'permissions',
        title: 'Permissions',
        translate: 'Permissions',
        type: 'item',
        url: '/permissions',
        svg: 'assets/icons/hmp-web/new-icon/Permissions.svg',
        exactMatch: true,
        roles: {
          entity: 'permissionscontent_entity',
          action: RoleActionsData.View,
        },
      },
      {
        id: 'email-settings',
        title: 'Email Settings',
        translate: 'Email Settings',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/Guest-Letters.svg',
        url: '/email-settings',
        roles: {
          entity: 'email_setting',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
      {
        id: 'payment-gateways',
        title: 'Payment Gateways',
        translate: 'Payment Gateways',
        type: 'item',
        roles: {
          entity: 'payment_gateways',
          action: RoleActionsData.View,
        },
        svg: 'assets/icons/hmp-web/new-icon/payment-gateway.svg',
        url: '/payment-gateways',
        exactMatch: true,
      },
      {
        id: 'guest-registration-settings',
        title: 'Guest Registration Settings',
        translate: 'Guest Registration Settings',
        type: 'item',
        svg: 'assets/icons/hmp-web/new-icon/guest-registration.svg',
        url: '/guest-registration-settings',
        roles: {
          entity: 'guest_registration_settings',
          action: RoleActionsData.View,
        },
        exactMatch: true,
      },
    ],
  },
]

export const navigation: FuseNavigation[] = [
  // ...mainNavigation,
  ...navigationFrontdesk,
  ...navigationPosCenter,
  ...navigationCityLedger,
  ...navigationSalesMarketing,
  ...navigationOps,
  ...navigationBudgetBuilder,
  ...navigationTravelX,
  ...navigationFinance,
  ...navigationWeb,
  ...navigationDirectBookingSetting,
  ...navigationSettings,
  // ...navigationOTA,
]
